<template>
  
  <div class="ibox">
    <div class="ibox-title">
      <h2>Copy Path</h2>
    </div>
    <div :class="'ibox-content p-md'+ (panelLoading ? ' sk-loading' : '')">

      <div v-if="panelLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <form role="form" @submit="onFormSubmit" name="copyStaticsForm">
        <div class="form-group row">
          <div class="col">
            <v-select :options="listApplications" v-model="form.applicationName" placeholder="Select an application" @keypress.enter.native.prevent="">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.applicationName"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>
          <div class="col">
            <v-select :options="listFolders" v-model="form.origin" placeholder="Select an origin" @keypress.enter.native.prevent="">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.origin"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
            <v-select :options="listPaths" :getOptionLabel="option => option.path" :reduce="option => option.path" v-model="form.path" placeholder="Select an path" @keypress.enter.native.prevent="" />
          </div>
          <div class="col">
            <v-select :options="listFolders" v-model="form.destination" placeholder="Select an destination" @keypress.enter.native.prevent="">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.destination"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>
          <div class="col">
            <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">Copy</button>
          </div>
        </div>
      </form>

      

    </div>
  </div>
        
</template>

<style scoped>
  
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Watch } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';

@Component({
  components: {}
})
export default class CopyPath extends Vue {

  listApplications:any[] = [
    "vigneron-online"
  ];
  listFolders:any[] = [
    "dev-staging",
    "dev-production",
    "staging",
    "production"
  ];
  listPaths:any[] = [];

  form:any = {
    applicationName: "",
    origin : "",
    destination: "",
    path: ""
  }

  panelLoading:boolean = false;

  onFormSubmit(evt:Event) {
    evt.preventDefault();

    var options:api.ApiVueOptions =  {
      app: this
    }

    var input = {
      ...this.form
    }

    this.panelLoading = true;

    return api.postAPI('/api/admin/storage/statics/copy', input,  options).then((response:any) => {
      this.panelLoading = false;
    })
  }

  updateListPaths() {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }

    var input = {
      applicationName : this.form.applicationName,
      path: this.form.origin
    }

    api.postAPI('/api/admin/storage/statics/paths', input, options).then((response:any) => {
      if(response.paths) {  
        this.listPaths = response.paths;
      }
      else {
        this.listPaths = [];
      }
    });
  }

  @Watch('form.origin')
  onValueChange(val: any, oldVal: any) {
    if(val == "") {
      this.listPaths = [];
    }
    else {
      this.updateListPaths();
    }
  }
  
}
</script>