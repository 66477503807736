<template>
  
  <div class="ibox">
    <div class="ibox-title">
      <h2>Check Files</h2>
    </div>
    <div :class="'ibox-content p-md'+ (panelLoading ? ' sk-loading' : '')">

      <div v-if="panelLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <form role="form" @submit="onCheckMissingInStorageFormSubmit" name="checkMissingInStorageForm">
        <div class="form-group row">
          <div class="col">
            <v-select :options="listDBs" :getOptionLabel="listDBsOptionLabel" v-model="form.targetDB" placeholder="Select an origin DB" @keypress.enter.native.prevent="">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.targetDB"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>
          <div class="col">
            <button class="btn btn-primary ladda-button check" data-style="zoom-in" type="submit">Check missing files in storage</button>
            <button class="btn btn-primary ladda-button remove" data-style="zoom-in" type="submit">Remove missing files in storage</button>
          </div>
        </div>
      </form>


      <form role="form" @submit="onCheckUnusedInStorageFormSubmit" name="checkUnusedInStorageForm">
        <div class="form-group row">
          <div class="col">
            <v-select :options="listFolders" v-model="form.pathPrefix" placeholder="Select an path prefix" @keypress.enter.native.prevent="">
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!form.pathPrefix"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
            </v-select>
          </div>
          <div class="col">
            <button class="btn btn-primary ladda-button check" data-style="zoom-in" type="submit">Check unused files in storage</button>
            <button class="btn btn-primary ladda-button remove" data-style="zoom-in" type="submit">Remove unused files in storage</button>
          </div>
        </div>
      </form>

      

    </div>
  </div>
        
</template>

<style scoped>
  
</style>



<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Watch } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';

@Component({
  components: {}
})
export default class CheckFiles extends Vue {


  form:any = {
    targetDB:"",
    pathPrefix:""
  }

  listFolders:any[] = [
    "dev-staging",
    "dev-production",
    "staging",
    "production"
  ];

  listDBs:any[] = [];

  panelLoading:boolean = false;

  laddaCheckMissingInStorageSubmit:Ladda.LaddaButton|null = null;
  laddaRemoveMissingInStorageSubmit:Ladda.LaddaButton|null = null;
  laddaCheckUnusedInStorageSubmit:Ladda.LaddaButton|null = null;
  laddaRemoveUnusedInStorageSubmit:Ladda.LaddaButton|null = null;

  mounted() {
    var missingCheckButton:HTMLButtonElement|null = document.querySelector( 'form[name=checkMissingInStorageForm] button.check' );
    this.laddaCheckMissingInStorageSubmit = Ladda.create(missingCheckButton!);

    var missingRemoveButton:HTMLButtonElement|null = document.querySelector( 'form[name=checkMissingInStorageForm] button.remove' );
    this.laddaRemoveMissingInStorageSubmit = Ladda.create(missingRemoveButton!);

    var unusedCheckButton:HTMLButtonElement|null = document.querySelector( 'form[name=checkUnusedInStorageForm] button.check' );
    this.laddaCheckUnusedInStorageSubmit = Ladda.create(unusedCheckButton!);

    var unusedRemoveButton:HTMLButtonElement|null = document.querySelector( 'form[name=checkUnusedInStorageForm] button.remove' );
    this.laddaRemoveUnusedInStorageSubmit = Ladda.create(unusedRemoveButton!);
  }

  created() {
    this.updateListDBs();
  }

  updateListDBs() {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: this
    }
    this.panelLoading = true;
    api.getAPI('/api/admin/database/configs', options).then((response:any) => {
      if(response.configs) {  
        this.listDBs = response.configs;
      }
      else {
        this.listDBs = [];
      }
      this.panelLoading = false;
    });
  }

  listDBsOptionLabel(option:any) {
    return option.title;
  }

  onCheckMissingInStorageFormSubmit(evt:SubmitEvent) {
    evt.preventDefault();

    var path = '/api/admin/storage/statics/check-missing-files-storage';
    var laddaButton = this.laddaCheckMissingInStorageSubmit!;
    if(evt.submitter && evt.submitter.classList.contains("remove")) {
      path = '/api/admin/storage/statics/remove-missing-files-storage';
      laddaButton = this.laddaRemoveMissingInStorageSubmit!;
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    var input = {
      targetDB : this.form.targetDB.title
    }

    laddaButton.start();

    return api.postAPI(path, input,  options).then((response:any) => {
      laddaButton.stop();
    })
  }

  onCheckUnusedInStorageFormSubmit(evt:SubmitEvent) {
    evt.preventDefault();

    var path = '/api/admin/storage/statics/check-unused-files-storage';
    var laddaButton = this.laddaCheckUnusedInStorageSubmit!;
    if(evt.submitter && evt.submitter.classList.contains("remove")) {
      path = '/api/admin/storage/statics/remove-unused-files-storage';
      laddaButton = this.laddaRemoveUnusedInStorageSubmit!;
    }

    var options:api.ApiVueOptions =  {
      app: this
    }

    var input = {
      pathPrefix : this.form.pathPrefix,
      targetDB : this.form.targetDB.title
    }

    laddaButton.start();

    return api.postAPI(path, input,  options).then((response:any) => {
      laddaButton.stop();
    })
  }
  
}
</script>