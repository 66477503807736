<template>
  <section id="content">
    <PageTitle title="Storage"></PageTitle>
    <div class="wrapper wrapper-content animated fadeInRight">

      <div class="row">
        <div class="col-lg-12">
          <CopyPath></CopyPath>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <CheckFiles></CheckFiles>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <CheckPictures></CheckPictures>
        </div>
      </div>

    </div>

  </section>
</template>

<style scoped>
  
</style>



<script lang="ts">
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import PageTitle from '../../panels/PageTitle.vue';
import CopyPath from '../../panels/admin/storage/CopyPath.vue';
import CheckFiles from '../../panels/admin/storage/CheckFiles.vue';
import CheckPictures from '../../panels/admin/storage/CheckPictures.vue';


@Component({
  components: { 
    PageTitle,
    CopyPath,
    CheckFiles,
    CheckPictures
  }
})
export default class Storage extends mixins(GenericPage) {
  
}
</script>